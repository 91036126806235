@import '../../styling/colors.scss';

.darkOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: $color-darkish;
}

.closedDoor,
.openDoor {
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 100px;
  overflow: hidden;
  .doorway {
    perspective: 100px;
    position: absolute;
    width: 50px;
    height: 100px;
    background-color: $color-white;
    .door {
      transform-style: preserve-3d;
      transform-origin: right center;
      width: 100%;
      height: 100%;
    }
  }
}

.openDoor {
  .door {
    transform: rotateY(-45deg);
  }
}

:export {
  red: $color-red;
  green: $color-green;
  purple: $color-purple;
}
