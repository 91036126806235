@import '../../styling/colors.scss';

.Door {
  width: 180px;
  color: $color-white;
  overflow-wrap: normal;
  margin: 0 20px 20px 0;

  &:nth-child(5n) {
    margin-right: 0;
  }

  .Box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border: solid 4px $color-purple;
    width: 176.59px;
    height: 167.27px;
    color: $color-red;
    text-align: center;
    text-decoration: none;
    transition: 0.3s;
    font-weight: bold;

    p {
      display: table-cell;
      vertical-align: middle;
      font-size: 20px;
      word-wrap: break-all;
      margin: 5px 0 0 0;
    }

    &:hover {
      border: solid 4px $color-purple-dark-box;
      p {
        opacity: 0.4;
      }
      .CardHover {
        visibility: visible;
      }
    }

    .CardHover {
      visibility: hidden;
      position: absolute;
      left: 59px;
      top: 56px;
    }
  }

  & a.Box:hover + .CardInfo .Description {
    display: block;
    max-height: 1000px;
  }

  .ContentList {
    padding: 0;
    word-wrap: break-word;
    overflow: hidden;
    transition: 0.7s ease;
    margin: 0;

    .Container {
      margin: 0;
    }

    .Title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 0;
    }

    .Info {
      margin-top: 5px;
    }
  }
}
