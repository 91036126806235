@import '../../styling/colors.scss';

.AudioPlayer {
  color: $color-white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .playPause {
    color: $color-white;
    cursor: pointer;
    background: transparent;
    border: none;
    margin: 10px;
    display: flex;
    align-items: center;
  }

  .FlexedWrapper {
    width: 100%;
  }

  .playerIcon {
    height: 1cm;
    width: 1cm;
  }

  .controlbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .scrubber {
    height: 50px;
    cursor: pointer;
    color: blueviolet;
  }
}
