@import '../../styling/colors.scss';

.logo {
  height: 35px;
  width: 140px;
}

.loginScreen {
  width: 100%;
  height: 100vh;
  background-color: $color-background;
  background-image: url('../../assets/svg/synchronicity-background.svg');
  background-size: cover;
  background-position: 50% 25%;
}

.loginSubHeading {
  color: $color-white;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.loginRow {
  display: flex;
  justify-content: center;
}

.loginHeading {
  color: $color-purple-light;
  font-size: 3.5em;
  font-weight: bold;
  text-align: center;
}

.loginContainer {
  position: fixed;
  margin-top: 150px;
  width: 500px;
  left: 50%;
  margin-left: -250px;
}

.loginFormContainer {
  padding: 20px;
}
