@import '../../styling/colors.scss';

.legend {
  position: absolute;
  max-width: 300px;
  padding: 7px;
  font-size: 16px;
  color: $color-purple;
  background-color: $color-black;
}
