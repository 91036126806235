@import '../../styling/colors.scss';

.Card {
  width: 180px;
  min-height: 0;
  color: $color-white;
  overflow-wrap: normal;
  flex-wrap: wrap;
  position: relative;
  margin-right: 16px;

  &:nth-child(5n) {
    margin-right: 0;
  }

  &.expanded {
    width: 100%;
    min-width: 100%;
    .player {
      width: 100%;
    }
    .cross {
      position: absolute;
      top: 0px;
      right: 0px;
      color: $color-green;
      margin: 10px;
      cursor: pointer;
    }
  }

  &.minimized {
    width: 180px;
  }

  .Box {
    border: solid 4px $color-green;
    width: 100%;
    height: 167.27px;
    color: $color-green-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 0.3s;
    padding: 5%;

    p {
      font-weight: bold;
      font-size: 20px;
      word-wrap: break-all;
      text-align: center;
      position: absolute;
      margin: 10%;
    }

    .hoverTitle {
      color: white;
      z-index: 1000;
    }

    &:hover {
      border: solid 4px $color-green-dark;
      p {
        opacity: 0.4;
      }
      .CardHover {
        visibility: visible;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .CardHover {
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & div.Box:hover + .CardInfo .Description {
    max-height: 1000px;
  }

  .CardInfo {
    text-align: left;
    .Description {
      word-wrap: break-word;
      max-height: 0px;
      overflow: hidden;
      transition: 0.7s ease;
    }
  }
}
