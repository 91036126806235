.assetContainer {
  display: flex;
  flex-direction: row;

  @media (max-width: 700px) {
    transition: 250ms;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.image {
  width: 20vw;
  height: 19vw;
  padding: 5px;

  @media (max-width: 600px) {
    transition: 250ms;
    width: 30vw;
    height: 30vw;
  }
}
