@import '../../styling/colors.scss';

.room,
.redRoom,
.greenRoom,
.syncShowBg,
.prideHouse279,
.prideHouse269,
.playlistRoom,
.rebirthRoom,
.pastfutureRoom,
.evolutionRoom {
  fill: $color-purple;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    fill: $color-purple-light;
  }
  &:active {
    fill: $color-purple-dark;
  }
}

.innerSection {
  fill: var(--inner-heart-color);
}

.outerSection {
  fill: var(--inner-heart-color);
}

.redRoom {
  fill: $color-red;
  &:hover {
    fill: $color-red-light;
  }
  &:active {
    fill: $color-red-dark;
  }
}

.greenRoom {
  fill: $color-green;
  &:hover {
    fill: $color-green-light;
  }
  &:active {
    fill: $color-green-dark;
  }
}

.syncShowBg {
  &:hover {
    fill: url(#synchronicityShow);
  }
  &:active {
    fill: url(#synchronicityShow);
    opacity: 0.8;
  }
}

.prideHouse279 {
  &:hover {
    fill: url(#prideRainbow-house-279);
  }
  &:active {
    fill: url(#prideRainbow-house-279);
    opacity: 0.8;
  }
}

.prideHouse269 {
  &:hover {
    fill: url(#prideRainbow-house-269);
  }
  &:active {
    fill: url(#prideRainbow-house-269);
    opacity: 0.8;
  }
}

.playlistRoom {
  &:hover {
    fill: url(#spotify);
  }
  &:active {
    fill: url(#spotify);
    opacity: 0.8;
  }
}

.rebirthRoom {
  &:hover {
    fill: url(#rebirth);
  }
  &:active {
    fill: url(#rebirth);
    opacity: 0.8;
  }
}

.pastfutureRoom {
  &:hover {
    fill: url(#pastfuture);
  }
  &:active {
    fill: url(#pastfuture);
    opacity: 0.8;
  }
}

.evolutionRoom {
  &:hover {
    fill: url(#evolution);
  }
  &:active {
    fill: url(#evolution);
    opacity: 0.8;
  }
}

.housePath {
  fill: inherit;
}