$color-purple: #7f22fd;
$color-purple-btn-main: rgb(201, 101, 231);
$color-purple-btn-main-hover: rgb(212, 137, 235);
$color-purple-btn-main-active: rgb(192, 71, 230);
$color-purple-light: #9d8dff;
$color-purple-light-btn: #a361ff;
$color-purple-dark: #5a17b8;
$color-purple-dark-box: rgba(127, 34, 253, 0.4);
$color-red: #fc4a4a;
$color-red-pride: rgb(236, 91, 91);
$color-red-light: rgb(255, 118, 118);
$color-red-dark: rgb(212, 52, 52);
$color-pride-modal: #0d0b39;
$color-white: #fff;
$color-darkish: rgba(0, 0, 0, 0.7);
$color-black: #000;
$color-green: #1ce68c;
$color-green-light: #62eaad;
$color-green-dark: #0c663e;
// Map colors
$color-outer-heart: #231e3f;
$color-heart-outer: rgb(12, 11, 22);
$color-background: #0b0a1b;
