@import '../../styling/colors.scss';

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .title {
    display: flex;
    flex-direction: row;
    color: $color-white;
    font-weight: bold;
    padding-bottom: 10px;
  }
  .titlePodcast {
    display: flex;
    flex-direction: row;
    color: $color-white;
    font-weight: bold;
  }

  .titleVideo {
    color: $color-white;
    font-weight: bold;
  }

  .icon {
    padding-right: 5px;
  }

  .image {
    height: 600px;
    width: 600px;
    align-self: center;

    @media (max-width: 700px) {
      transition: 250ms;
      height: 70vw;
      width: 70vw;
    }
  }

  .liveImage {
    height: 50vh;
    width: 50vh;
    align-self: center;

    @media (max-width: 500px) {
      align-self: center;
      transition: 250ms;
      height: 90vw;
      width: 90vw;
    }
  }

  .livelinks {
    align-self: center;
  }

  .button {
    align-self: flex-end;
  }

  .PodcastContent {
    color: $color-white;
    width: 70vw;
    height: 100vh;
    margin: 0px auto;
    padding: 0 0px;
    text-align: justify;
    cursor: initial;
    // overflow: scroll;

    .Podcasts {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 30px;
    }
  }

  .AudioContent {
    color: $color-white;
    height: 100vh;
    width: 100vw;
    text-align: justify;
    cursor: initial;
    padding-top: 20vh;
    padding-left: 15vw;
    padding-right: 15vw;
  }
}

.fullWidthContent {
  width: 100%;

  .fullWidthCenteredHeading {
    color: $color-white;
    text-transform: uppercase;
    text-align: center;
  }

  .prideButton,
  .button {
    max-width: 250px;
    margin: 30px auto;
  }
}

.fullWidthHeader {
  width: 100%;

  img {
    width: 100%;
  }
}

.contentRow {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.overlayWrap {
  position: relative;
  user-select: none;

  img {
    max-width: 100vw;
    max-height: 90vh;
  }

  svg {
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;

    path {
      cursor: pointer;
      opacity: 0;
      transition: 500ms; // does not work together with animation
    }

    path:not(:hover) {
      animation-name: pulseGlow;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    path:hover {
      opacity: 0.3;
    }
  }
}

@keyframes pulseGlow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.15;
  }
  100% {
    opacity: 0;
  }
}

.slideshow {
  user-select: none;
  position: relative;
  margin-top: 40px;
  width: 100%;

  .backButton {
    user-select: none;
    position: absolute;
    top: 0px;
    left: 0px;
    color: $color-white;
    font-size: 20px;
    font-weight: 500;
    z-index: 1;
    cursor: pointer;
  }

  .gallery {
    img {
      width: auto;
      max-width: 100vw;
      max-height: 100vh;
    }

    button:disabled {
      display: none;
    }
  }
}
