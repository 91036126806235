@import '../../styling/colors.scss';

.countdownVideo {
  position: absolute;
  width: 88vw;
  height: 37.1vw;
  user-select: none;
  top: 6vw;
  left: 6vw;

  iframe {
    position: absolute;
    display: block;
    width: 100%;
    background-color: $color-background;
  }

  .countdown {
    position: absolute;
    display: table;
    height: 50%;
    width: 50%;
    text-align: center;
    color: white;
    z-index: 1;
    top: 15.3vw;
    left: 19vw;

    span {
      font-size: 3vw;
      text-shadow: 2px 2px $color-background;
      display: table-cell;
      vertical-align: middle;
      opacity: 0.5;
    }
  }
}

.closeButton {
  user-select: none;
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: 5px;
  margin-top: 5px;
  width: 20px;
  color: $color-white;
  fill: white;
  z-index: 2;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
}

.fullscreenVideo {
  position: absolute;
  width: 80vw;
  height: 45vw;
  user-select: none;
  top: 3vw;
  left: 10vw;

  iframe {
    position: absolute;
    display: block;
    width: 100%;
    background-color: $color-background;
  }
}
