@import '../../styling/colors.scss';

a.button {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  border-radius: 2px;
  background-color: $color-purple;
  border: none;
  text-decoration: none;
  max-width: 100%;
}

a.button:hover {
  background-color: $color-purple-light-btn;
}

a.button:active {
  background-color: $color-purple-dark;
}

.buttonText {
  padding: 10px;
  font-size: 14px;
  color: white;
}

.mainButton {
  @extend a.button;
  background-color: $color-purple-btn-main;
}

.mainButton:hover {
  background-color: $color-purple-btn-main-hover;
}

.mainButton:active {
  background-color: $color-purple-btn-main-active;
}

.pride {
  @extend a.button;
  font-weight: bold;
  background-color: $color-red-pride !important;
  border-radius: 5px;
  .buttonText {
    font-size: 16px;
  }
}
