@import 'styling/fonts.module.scss';

/*
 * Global styling is kept in this file, keep it to a very strict minimum! Try to always
 * style and reuse components instead of applying global styling! Nothing more than fonts,
 * margin and padding should be needed. For global styling-variables such as colors and
 * sizes, look in styling > 'varaibles.scss'
 */

/*
*  Standardize margin and padding in order to fill up the full page
*/
body {
  margin: 0;
  padding: 0;
}

* {
  font-family: 'Proxima Nova', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  // removes blue border around buttons that are selected
  button:focus {
    outline: 0;
  }
}
