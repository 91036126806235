.iframe {
  align-self: center;
  position: fixed;
  z-index: 2;
}

@media (max-width: 1000px) {
  .iframe {
    align-self: center;
    position: fixed;
    z-index: 2;
  }
}
