@import '../../styling/colors.scss';

.navigationBar {
  padding: 20px;
  color: $color-white;
  background-color: $color-black;
  font-size: 20px;
  font-weight: 500;
  z-index: 1;
  top: 0;
  position: sticky;
  cursor: pointer;
}

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0;

  /* Preserve aspect ratio */
  min-width: 100%;
  min-height: 100%;
  background-color: $color-darkish;
}

.backdropPod {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Preserve aspect ratio */
  min-width: 100%;
  min-height: 100vh;
  background-color: $color-darkish;
}

.prideModal {
  background: $color-pride-modal;
  min-width: 100%;
  min-height: 100vh;
}
