/*!
 * Web Fonts from fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at http://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2016 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Mark Simonson Studio
 * License URL: https://www.fontspring.com/licenses/mark-simonson-studio/webfont
 *
 *
 */

$font-path: '../assets/fonts/';

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url($font-path+'proximanova_regular/proximanova-regular-webfont.eot');
  src: url($font-path+'proximanova_regular/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url($font-path+'proximanova_regular/proximanova-regular-webfont.woff2') format('woff2'),
    url($font-path+'proximanova_regular/proximanova-regular-webfont.woff') format('woff'),
    url($font-path+'proximanova_regular/proximanova-regular-webfont.ttf') format('truetype'),
    url($font-path+'proximanova_regular/proximanova-regular-webfont.svg#proxima_novaregular') format('svg');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 500;
  src: url($font-path+'proximanova_medium/proximanova-medium-webfont.eot');
  src: url($font-path+'proximanova_medium/proximanova-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url($font-path+'proximanova_medium/proximanova-medium-webfont.woff2') format('woff2'),
    url($font-path+'proximanova_medium/proximanova-medium-webfont.woff') format('woff'),
    url($font-path+'proximanova_medium/proximanova-medium-webfont.ttf') format('truetype'),
    url($font-path+'proximanova_medium/proximanova-medium-webfont.svg#proxima_novamedium') format('svg');
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  src: url($font-path+'proximanova_bold/proximanova-bold-webfont.eot');
  src: url($font-path+'proximanova_bold/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url($font-path+'proximanova_bold/proximanova-bold-webfont.woff2') format('woff2'),
    url($font-path+'proximanova_bold/proximanova-bold-webfont.woff') format('woff'),
    url($font-path+'proximanova_bold/proximanova-bold-webfont.ttf') format('truetype'),
    url($font-path+'proximanova_bold/proximanova-bold-webfont.svg#proxima_novabold') format('svg');
}
